import SmartBanner from 'smart-app-banner';

$(() => {
  const showOrig = SmartBanner.prototype.show;
  const hideOrig = SmartBanner.prototype.hide;

  const show = () => {
    showOrig();

    const offset = '70px';
    $('.navbar-fixed-top').css('top', offset);
    $('.sidebar').css('padding-top', offset);
  };

  const hide = () => {
    hideOrig();

    $('.navbar-fixed-top').css('top', '0');
    $('.sidebar').css('padding-top', '0');
  };

  SmartBanner.prototype.show = show;
  SmartBanner.prototype.hide = hide;

  const smartBanner = new SmartBanner({
    daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    title: 'TOKIUM',
    author: '株式会社TOKIUM',
    button: 'VIEW',
    store: {
      ios: 'On the App Store',
      android: 'In Google Play',
    },
    price: {
      ios: 'FREE',
      android: 'FREE',
    },
    // force: 'ios', // Uncomment for platform emulation
  });
});
